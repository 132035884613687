<template>
	<div class="view pa24" >
		<div style="display: flex;flex-wrap: wrap;"> 
			<div class="mr10">
				<span class="mr10">单号</span>
				<el-input clearable class="w220 mr10" v-model="search.name" placeholder="请输入单号" />
			</div>
			<div class="mr10">
				<span class="mr10">账变类型</span>
				<el-select clearable v-model="search.change_type" class="w220 mb10 mr10" placeholder="请选择账变类型">
					<el-option v-for="(item,index) in typeList" :key="'info4'+ index" :label="item.name"
						:value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="mr10">
				<span class="mr10">时间</span>
				<el-date-picker :picker-options="pickerOptions" v-model="search.date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
			<el-button class="ml10 h34" type="primary" @click="upsearch">查询</el-button>
			<!-- <el-button class="mr10 h34" type="danger" @click="daochu">导出</el-button> -->
		</div>
		<commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
			<template v-slot:table>
				<el-table-column prop="change_type" align="center" label="账变类型"  />
				<el-table-column align="center" label="钱包类型" >
					<template slot-scope="scope">
						<div v-if="scope.row.type==1">代收钱包</div>
						<div v-if="scope.row.type==2">代付钱包</div>
					</template>
				</el-table-column>
				<el-table-column prop="left_amount" align="center" label="原金额" />
				<el-table-column prop="createTime" align="center" label="变动金额" >
					<template slot-scope="scope">
						<div v-if="scope.row.action==1" class="d-flex justify-content-center">
							<div style="color: #65ca00;">↑</div>
							<div style="color: #65ca00;">{{scope.row.change_amount}}</div>
						</div>
						<div v-if="scope.row.action==2" class="d-flex justify-content-center">
							<div style="color: #ff0000;">↓</div>
							<div style="color: #ff0000;">{{scope.row.change_amount}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="right_amount" align="center" label="变动后金额"/>
				<!-- <el-table-column prop="createName" align="center" label="通道"/> -->
				<el-table-column prop="create_time" align="center" label="创建时间"/>
				<el-table-column prop="source_sn" align="center" label="单号"/>
				<el-table-column prop="remark" align="center" label="备注"/>
			</template>
		</commonTable>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	import {
		getdict,
		zbLists,
	} from "@/api/settlement";
	import Moment from 'moment';
	export default {
		name: 'windowSet',
		data() {
			return {
        pickerOptions: {
          shortcuts: [{
            text: '今日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '昨日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, start]);
            }
          },{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
				search:{
					name:'',
					change_type:'',
					date:''
				},
				typeList: [],
				tableData: [],
				currentPage: 1, //当前页
				pageSize: 10, //显示条数
				loading: false, //表格加载
				total: 0, //总条数
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			this.getList()
			this.queryPage();
		},

		methods: {
			getList(){
				getdict({type:'change_type'})
					.then((res) => {
						this.typeList = res.data.change_type
					})
					.catch((err) => {
						this.$message.error(err.msg);
					});
			},
			async queryPage(payload) {
				let data = {
					page_size: this.pageSize,
					page_no: this.currentPage,
					...payload
				};
				try {
					this.loading = true;
					const result = await zbLists(data);
					this.loading = false;
					const {
						count,
						lists
					} = result.data;
					this.tableData = lists;
					this.total = count;
				} catch (error) {
					this.tableData = []
					this.loading = false;
				}
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.queryPage();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.queryPage();
			},
			/**@method 删除
			 * @param {String}
			 */
			async del(val) {
				try {
					await delWindowsConfig({
						windowsConfigId: val
					});
					await this.queryPage();
				} catch (error) {
					console.log(error);
				}
			},
			upsearch(){
				this.currentPage = 1;
				let data = {
					source_sn:this.search.name,
					change_type:this.search.change_type,
				};
				if(this.search.date) {
					data.start_time = Moment(this.search.date[0]).format('YYYY-MM-DD 00:00:00');
					data.end_time = Moment(this.search.date[1]).format('YYYY-MM-DD 23:59:59');
				}
				this.queryPage(data);
			},
			daochu(){
				
			}
		},
	};
</script>

<style lang="scss" scoped>
</style>